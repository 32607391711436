import React, { useState, useEffect, useRef } from 'react';
import './article.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faChevronRight, faTimes, faExpand } from '@fortawesome/free-solid-svg-icons';

// Dynamically import all images from the articles directory
const images = require.context('../../assets/images/articles', false, /\.webp$/);

function Article({ stub, alt }) {
  // Get all image paths that match the stub pattern
  const imagePaths = images.keys().filter(key => key.includes(stub));

  const [currentIndex, setCurrentIndex] = useState(0);
  const [isFullscreen, setIsFullscreen] = useState(false);
  const touchStartX = useRef(null);
  const touchEndX = useRef(null);

  function handleNav(direction) {
    setCurrentIndex(prevIndex => {
      const newIndex = prevIndex + direction;
      if (newIndex < 0) {
        return imagePaths.length - 1;
      } else if (newIndex >= imagePaths.length) {
        return 0;
      } else {
        return newIndex;
      }
    });
  }

  function handleOpen() {
    setIsFullscreen(!isFullscreen);
  }

  useEffect(() => {
    function handleClickOutside(event) {
      if (isFullscreen && !event.target.closest('.nav') && !event.target.closest('.open')) {
        setIsFullscreen(false);
      }
    }

    if (isFullscreen) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isFullscreen]);

  function handleTouchStart(event) {
    touchStartX.current = event.changedTouches[0].screenX;
  }

  function handleTouchEnd(event) {
    touchEndX.current = event.changedTouches[0].screenX;
    handleSwipe();
  }

  function handleSwipe() {
    if (touchStartX.current - touchEndX.current > 50) {
      handleNav(1); // Swipe left
    }

    if (touchEndX.current - touchStartX.current > 50) {
      handleNav(-1); // Swipe right
    }
  }

  return (
    <>
      <div
        className={`article ${isFullscreen ? 'fullscreen' : ''}`}
        onTouchStart={handleTouchStart}
        onTouchEnd={handleTouchEnd}
      >
        {isFullscreen && (
          <button className='close' onClick={handleOpen}>
            <FontAwesomeIcon icon={faTimes} />
          </button>
        )}
        <button className='nav' onClick={() => handleNav(-1)} disabled={currentIndex === 0}>
          <div className='article-prev'>
            <FontAwesomeIcon id="theme-select" icon={faChevronLeft} />
          </div>
        </button>
        <button className='open' onClick={handleOpen}>
          {imagePaths.map((path, index) => (
            <React.Fragment key={index}>
              <div className={`article-page ${index === currentIndex ? "show" : "hide"}`}>
                <img
                  src={images(path)}
                  alt={`${alt} ${index + 1}`}
                />
                {!isFullscreen && (
                  <div className='fullscreen-icon' onClick={handleOpen}>
                    <FontAwesomeIcon icon={faExpand} />
                  </div>
                )}
              </div>
            </React.Fragment>
          ))}
        </button>
        <button className='nav' onClick={() => handleNav(1)} disabled={currentIndex === imagePaths.length - 1}>
          <div className='article-next'>
            <FontAwesomeIcon id="theme-select" icon={faChevronRight} />
          </div>
        </button>
      </div>
    </>
  );
}

export default Article;

