import React from 'react';
import { Hero } from "../../components/header/header";
import Article from "./article";

function Articles() {
    const pageTitle = 'Publications';
    document.title = global.AppName + ' - ' + pageTitle;
    return (
        <>        
            <Hero title={"Cotmarsh Tannery CIC"} subtitle={"News and Magazine Articles"}></Hero>

            {/* start main */}
            <div className="main_bg">
                <div className="wrap">
                    <div className="main" id="home">
                        <h2>Publications</h2>
                        <p>Some articles that have been published about the tannery.</p>
                        {/* <h3>some things you should know about us</h3> */}
                        <section className='articles'>
                          <Article stub='british-white-cattle-society-journal-2025' alt='British White Cattle Society Breed Journal 2025' /> 
                          <Article stub='world-leather-august-2024' alt='World Leather Magazine August 2024' /> 
                          <Article stub='cleft-stick-summer-2024' alt='Cleft Stick Magazine summer 2024' />                             
                        </section>
                        <div className="clear"></div>
                    </div>
                </div>
            </div>

        </>
    );
}

export default Articles;