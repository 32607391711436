import React from 'react';
import { Route, Routes } from 'react-router-dom';
import Home from './pages/home/home';
import Tannery from './pages/tannery/';
import Tanning from './pages/tannery/tanning';
import Salting from './pages/tannery/salting';
import Blog from './pages/blog/';
import Churchill from './pages/home/churchill';
import Newsletter from './pages/newsletter';
import Articles from './pages/articles';
import Makers from './pages/makers';
import Survey from './pages/survey';
import About from './pages/home/about';
import NewsletterSignup from './pages/newsletter/newsletter-signup';
import Support from './pages/home/support';
import Story from './pages/home/story';
import NotFound from './pages/home/404';
import Template from './pages/home/template';
import Contact from './pages/contact/';
import Header from "./components/header/";
import Footer from "./components/footer/";
import ScrollToPosition from "./components/ScrollToPosition";
const App = () => {
  return (
    <>
      <Header></Header>
      <ScrollToPosition>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/the-tannery" element={<Tannery />} />
          <Route path="/the-tannery/veg-tanning" element={<Tanning />} />
          <Route path="/the-tannery/salting-hides" element={<Salting />} />
          <Route path="/blog/:category?" element={<Blog />} />
          <Route path="/churchill-fellowship" element={<Churchill />} />
          <Route path="/newsletter/:release?" element={<Newsletter />} />
          <Route path="/articles/:article?" element={<Articles />} />
          <Route path="/leather/makers-directory" element={<Makers />} />
          <Route path="/survey" element={<Survey />} />
          <Route path="/about-us" element={<About />} />
          <Route path="/our-story" element={<Story />} />
          <Route path="/newsletter-signup" element={<NewsletterSignup />} />
          <Route path="/support" element={<Support />} />
          <Route path="/contact-us" element={<Contact />} />
          <Route path="/template" element={<Template />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </ScrollToPosition>
      <Footer></Footer>
    </>
  );
 };
 
 export default App;