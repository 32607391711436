import React, { useState, useEffect, useCallback } from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircle, faMoon, faSun } from '@fortawesome/free-solid-svg-icons';

const THEME = {
  DARK: 'dark',
  LIGHT: 'light'
};

function ThemeSelect() {
  const [systemThemeDark, setSystemThemeDark] = useState(window.matchMedia('(prefers-color-scheme: dark)').matches);

  // Sets the theme, local storage if it exists , otherwise system theme
  const [theme, setTheme] = useState(() => {
    const savedTheme = localStorage.getItem('theme');
    if (savedTheme) return savedTheme;
    
    return systemThemeDark
      ? THEME.DARK 
      : THEME.LIGHT;
  });

  // Memoized theme oppositeTheme calculation
  const oppositeTheme = useCallback((currentTheme) => {
    return currentTheme === THEME.DARK ? THEME.LIGHT : THEME.DARK;
  }, []);

  // Handle theme changes link click
  const handleThemeChange = useCallback((event) => {
    event.preventDefault();
    setTheme(oppositeTheme(theme));
  }, [theme, oppositeTheme]);

  // Effect to update DOM and localStorage
  useEffect(() => {
    try {
      // Update HTML data attribute
      document.documentElement.setAttribute('data-theme', theme);
      
      // Update theme link text and aria-label
      const themeLink = document.querySelector('#theme-changer + a');
      if (themeLink) {
        themeLink.title = `Change to ${oppositeTheme(theme)} mode`;
        themeLink.setAttribute(
          'aria-label',
          `Change to ${oppositeTheme(theme)} mode`
        );
      }

      // Update localStorage
      if ((systemThemeDark && theme === THEME.LIGHT) || (!systemThemeDark && theme === THEME.DARK)) {
        // set local storage if theme not the same as system theme
        localStorage.setItem('theme', theme);
      } else {
        // remove local storage if theme is the same as system theme
        localStorage.removeItem('theme');
      }
    } catch (error) {
      console.error('Error updating theme:', error);
    }
  }, [theme, systemThemeDark, oppositeTheme]);

  // Listen for system theme changes
  useEffect(() => {
    const mediaQuery = window.matchMedia('(prefers-color-scheme: dark)');
    
    const handleSystemThemeChange = (e) => {
      if (!localStorage.getItem('theme')) {
        // If no theme is stored, update theme to match system theme
        setSystemThemeDark(e.matches);
      }
    };

    mediaQuery.addEventListener('change', handleSystemThemeChange);
    
    return () => {
      mediaQuery.removeEventListener('change', handleSystemThemeChange);
    };
  }, []);

  return (
    <div id="theme-changer">
      <Link to="#" onClick={handleThemeChange} title={`Switch to ${oppositeTheme(theme)} mode`} aria-label={`Switch to ${oppositeTheme(theme)} mode`}>
        {oppositeTheme(theme) === THEME.DARK ? (
          <FontAwesomeIcon id="theme-select" icon={faMoon} />
        ) : (
          <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" className="bi bi-sun" viewBox="0 -2 18 18">
            <path d="M8 11a3 3 0 1 1 0-6 3 3 0 0 1 0 6m0 1a4 4 0 1 0 0-8 4 4 0 0 0 0 8M8 0a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-1 0v-2A.5.5 0 0 1 8 0m0 13a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-1 0v-2A.5.5 0 0 1 8 13m8-5a.5.5 0 0 1-.5.5h-2a.5.5 0 0 1 0-1h2a.5.5 0 0 1 .5.5M3 8a.5.5 0 0 1-.5.5h-2a.5.5 0 0 1 0-1h2A.5.5 0 0 1 3 8m10.657-5.657a.5.5 0 0 1 0 .707l-1.414 1.415a.5.5 0 1 1-.707-.708l1.414-1.414a.5.5 0 0 1 .707 0m-9.193 9.193a.5.5 0 0 1 0 .707L3.05 13.657a.5.5 0 0 1-.707-.707l1.414-1.414a.5.5 0 0 1 .707 0m9.193 2.121a.5.5 0 0 1-.707 0l-1.414-1.414a.5.5 0 0 1 .707-.707l1.414 1.414a.5.5 0 0 1 0 .707M4.464 4.465a.5.5 0 0 1-.707 0L2.343 3.05a.5.5 0 1 1 .707-.707l1.414 1.414a.5.5 0 0 1 0 .708"/>
          </svg>
        )}
      </Link>
    </div>
  );
}

export default ThemeSelect;
